import { Card, Table } from '@equitymultiple/react-eui';
import moment from 'moment';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import formatCurrency from 'utils/formatCurrency';
import { investmentAccountTypeLabels } from 'views/Accounts/constants';
import { getAccountStatus } from 'views/Users/helpers';

import {
  InvestmentAccount,
  useGetUserAccountsQuery,
} from '../../../../__generated__';
import * as ovStyles from '../overrides.module.scss';
import InvestmentAccountActions from './InvestmentAccountActions';
import * as styles from './InvestmentAccounts.module.scss';

export const columnHeaders = [
  'ID',
  'Name',
  'Account Type',
  'Status',
  'Date Created',
  'Capital Balance',
  '',
];

const getRows = (accounts: (InvestmentAccount | null | undefined)[]) =>
  accounts.map((account: InvestmentAccount | null | undefined) => ({
    cells: account
      ? [
          `#${account.id}`,
          account.entityName,
          account.type ? investmentAccountTypeLabels[account.type] : null,
          getAccountStatus(account.status),
          account.createdAt
            ? moment(new Date(account.createdAt)).format('MM/DD/YYYY')
            : '',
          formatCurrency(account.capitalBalance),
          <InvestmentAccountActions
            accountId={account.id as string}
            status={account.status as string}
            key="actions"
          />,
        ]
      : [],
  }));

const InvestmentAccounts: React.FC = () => {
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data, loading, fetchMore } = useGetUserAccountsQuery({
    variables: {
      userId: id,
      pagination: {
        page,
        pageSize: rowsPerPage,
      },
    },
  });

  const fetchMoreAccounts = (pageNumber: number, pageSize: number) => {
    fetchMore({
      variables: {
        pagination: {
          page: pageNumber,
          pageSize,
        },
      },
    });
  };

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const pageNumber = newPage + 1;
    setPage(pageNumber);
    fetchMoreAccounts(pageNumber, rowsPerPage);
  };

  const handleRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const pageSize = parseInt(event.currentTarget.value);
    setRowsPerPage(pageSize);
    setPage(1);
    fetchMoreAccounts(1, pageSize);
  };

  const displayCount = data?.investmentAccounts?.pageInfo?.count || 0;
  const hasAccounts =
    data?.investmentAccounts?.data && data.investmentAccounts.data.length > 0;

  return (
    <Card
      className={ovStyles.cardContainer}
      data-testid="userAccounts"
      id="accounts"
    >
      <div className={styles.top}>
        <div>
          <h6 data-testid="headingAccounts">Accounts</h6>
          <span className="subText">Displaying: {displayCount}</span>
        </div>
      </div>
      <Table
        data-testid="accountsTable"
        columnHeaders={columnHeaders}
        loading={loading}
        loadingRows={10}
        pagination={{
          page: hasAccounts ? page - 1 : 0,
          rowsPerPage,
          onRowsPerPageChange: handleRowsPerPageChange,
          onPageChange: handlePageChange,
          count: data?.investmentAccounts?.pageInfo?.count || 0,
        }}
        rows={
          hasAccounts
            ? getRows(data?.investmentAccounts?.data as InvestmentAccount[])
            : [{ cells: ['No accounts found'] }]
        }
      />
    </Card>
  );
};

export default InvestmentAccounts;
