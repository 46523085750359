import { Button, Card, FileUploader, Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

// import {
//   CreateSponsorInput,
//   EditSponsorInput,
// } from '../../../__generated__/index';
import { sponsorSchema } from './validation';

//type FormValues = CreateSponsorInput | EditSponsorInput;
// eslint-disable-next-line
type FormValues = Record<string, any>;

export interface Props {
  defaultValues?: DefaultValues<FormValues>;
  loading: boolean;
  onSubmit(submitData: FormValues): unknown;
}

const SponsorForm: React.FC<Props> = ({ onSubmit, loading, defaultValues }) => {
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<DefaultValues<FormValues>>({
    mode: 'onBlur',
    resolver: yupResolver(sponsorSchema),
    defaultValues,
  });

  const formValues = getValues();

  useEffect(() => {
    if (defaultValues && !formValues?.name) {
      reset({ ...defaultValues } as DefaultValues<FormValues>);
    }
  }, [defaultValues, reset, formValues?.name]);

  return (
    <>
      <Card className={`contentNarrow firstChildMarginTop0`}>
        <form onSubmit={handleSubmit(onSubmit)} data-testid="sponsorForm">
          <Col lg={4} md={6}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  data-testid="sponsorName"
                  value={field.value || ''}
                  id="sponsorName"
                  label="Sponsor Name"
                  errorMessage={errors.name?.message as string}
                />
              )}
            />
          </Col>
          <p>Sponsor Logo</p>
          <Controller
            name="logo"
            control={control}
            render={({ field }) => (
              <FileUploader
                {...field}
                onRemove={() => field.onChange(null)}
                upload={(uploadData: File) => field.onChange(uploadData)}
                showImage
                existingFile={
                  field.value?.url
                    ? {
                        ...field.value,
                        name: field.value.url.split('/').pop(),
                      }
                    : field.value
                }
                errorMessage={errors.logo?.message as string}
                acceptedFileTypes={['JPG', 'PNG']}
              />
            )}
          />
          <Row>
            <Col className="alignItemsCenter" xs={8}>
              <Link type="button" className="textLink underline" to="/sponsors">
                Cancel
              </Link>
            </Col>
            <Col xs={4}>
              <Button type="submit" className="floatRight" loading={loading}>
                Submit
              </Button>
            </Col>
          </Row>
        </form>
      </Card>
    </>
  );
};

export default SponsorForm;
