import {
  PaymentMethod,
  useGetInvestmentQuery,
  useRefundInvestmentMutation,
} from '__generated__';
import {
  Button,
  Card,
  Input,
  RadioButton,
  Select,
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import formatCurrency from 'utils/formatCurrency';
import { setFieldProps } from 'utils/formHelpers';
import { numberMaskOptions } from 'utils/masks';
import { capitalize } from 'utils/stringFormatting';

import { defaultPaymentMethods } from '../constants';
import { getPaymentMethodOptions } from '../helpers';
import { investmentSchema } from './validation';

const messages = {
  loading: 'Refunding investment',
  error: 'An error occurred while refunding the investment',
  success: 'Investment refunded',
};

const paymentMethods = defaultPaymentMethods.filter(
  (paymentMethod) => paymentMethod !== PaymentMethod.Rollover,
);

const paymentMethodOptions = getPaymentMethodOptions(paymentMethods);

const RefundInvestment = () => {
  const { offeringId, id } = useParams();
  const navigate = useNavigate();
  const {
    data: investmentData,
    error,
    loading,
  } = useGetInvestmentQuery({
    variables: {
      offeringId,
      investmentId: id,
    },
  });

  const hasError = !!(error || investmentData?.investment?.error);

  const investment = investmentData?.investment?.investment;
  const offering = investment?.offering?.offering;
  const closing = investment?.closing?.closing;

  const [refundInvestment, refundInvestmentState] =
    useRefundInvestmentMutation();
  const submitting = refundInvestmentState.loading;

  const [defaultValuesLoaded, setDefaultValuesLoaded] = useState(false);

  const defaultValues = {
    paymentMethod: null,
    fullRefund: true,
    amount: investment?.amount || '',
  };

  const {
    control,
    clearErrors,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(investmentSchema),
    defaultValues,
  });

  const formValues = getValues();
  const fullRefund = watch('fullRefund');
  const handleRefundTypeChange = (type: string) => {
    if (type === 'full') {
      setValue('fullRefund', true);
      setValue('amount', investment?.amount);
      clearErrors('amount');
    } else {
      setValue('fullRefund', false);
    }
  };

  useEffect(() => {
    if (investment?.amount && !defaultValuesLoaded) {
      reset({ ...formValues, amount: investment.amount });
      setDefaultValuesLoaded(true);
    }
  }, [
    investment?.amount,
    defaultValuesLoaded,
    setDefaultValuesLoaded,
    formValues,
    reset,
  ]);

  useEffect(() => {
    if (hasError) {
      toast.error('An error occurred while loading this investment');
      navigate(`/offerings/${offeringId}/investments`);
    }
  }, [hasError, navigate, offeringId]);

  const onSubmit = (values) => {
    callMutationWithToastMessages(refundInvestment, messages, {
      variables: {
        investment: {
          offeringId,
          id,
          amount: parseFloat(values.amount),
          fullRefund: values.fullRefund,
          paymentMethod: values.paymentMethod,
        },
      },
    }).then((res) => {
      if (res.data?.refundInvestment?.investment?.id) {
        navigate(`/offerings/${offeringId}/investments`);
      }
    });
  };

  return (
    <>
      <h2 className="contentNarrow">
        {loading ? (
          <Skeleton width={500} style={{ maxWidth: '100%' }} />
        ) : (
          `Refund Investment in ${offering?.title}`
        )}
      </h2>
      {loading && !hasError ? null : (
        <Card className="contentNarrow">
          <div data-testid="investmentId">
            <strong>Investment ID:</strong> {investment?.id}
          </div>
          <div data-testid="closingDetails">
            <strong>Closing:</strong> {closing?.name} (
            {capitalize(closing?.stage || '')})
          </div>
          <div data-testid="investmentAccountDetails">
            <strong>Investment Account:</strong>{' '}
            {`${investment?.investmentAccount?.entityName} (${capitalize(
              investment?.investmentAccount?.type || '',
            )})`}
          </div>
          <div data-testid="investmentAmount">
            <strong>Current Investment Amount:</strong>{' '}
            {formatCurrency(investment?.amount)}
          </div>
          <hr className="marginTop10 margin30" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                <Controller
                  name="paymentMethod"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...setFieldProps(field, errors)}
                      label="Payment Method"
                      options={paymentMethodOptions}
                      data-testid="paymentMethodSelect"
                      id="paymentMethodSelect"
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Controller
                  name="fullRefund"
                  control={control}
                  render={({ field }) => (
                    <div className="margin30">
                      <RadioButton
                        {...field}
                        id="fullRefundTrue"
                        label="Full Refund"
                        checked={field.value === true}
                        onChange={() => handleRefundTypeChange('full')}
                        data-testid="fullRefundTrue"
                      />
                      <RadioButton
                        {...field}
                        id="fullRefundFalse"
                        label="Partial Refund"
                        checked={field.value === false}
                        onChange={() => handleRefundTypeChange('partial')}
                        errorMessage={errors.fullRefund?.message as string}
                      />
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...setFieldProps(field, errors)}
                      label="Amount"
                      dollarMask
                      allowDecimal
                      inputMaskOptions={numberMaskOptions}
                      disabled={fullRefund}
                    />
                  )}
                />
              </Col>
            </Row>

            <Row className="marginTop30">
              <Col className="alignItemsCenter" xs={4}>
                <Link
                  type="button"
                  className="textLink underline"
                  to={`/offerings/${offeringId}/investments`}
                >
                  Cancel
                </Link>
              </Col>
              <Col xs={8}>
                <Button
                  type="submit"
                  disabled={submitting}
                  className="floatRight"
                >
                  Post Refund
                </Button>
              </Col>
            </Row>
          </form>
        </Card>
      )}
    </>
  );
};

export default RefundInvestment;
