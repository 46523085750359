import { ToggleableTooltip } from '@equitymultiple/react-eui';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  useDisableTwoFactorAuthenticationMutation,
  useSuspendUserMutation,
  useUnlockUserMutation,
} from '../../../../__generated__';
import callMutationWithToastMessages from '../../../../utils/callMutationWithToastMessages';
import * as styles from './UserActions.module.scss';

export interface Props {
  deletable: boolean;
  locked: boolean;
  setDeleteClicked: (deleteClicked: boolean) => void;
  setResetPasswordClicked: (resetClicked: boolean) => void;
  suspended: boolean;
  twoFactorEnabled: boolean;
  userId: string;
}

const disableMessages = {
  loading: 'Disabling two-factor authentication',
  error: 'An error occurred while disabling two-factor authentication',
  success: 'Two-factor authentication disabled for the user',
};

const suspendMessages = {
  loading: 'Suspending',
  error: 'An error occurred while suspending the user',
  success: 'User suspended',
};

const unsuspendMessages = {
  loading: 'Unsuspending',
  error: 'An error occurred while unsuspending the user',
  success: 'User unsuspended',
};

const unlockMessages = {
  loading: 'Unlocking',
  error: 'An error occurred while unlocking the user',
  success: 'User is now unlocked',
};

const UserActions: React.FC<Props> = ({
  deletable,
  suspended,
  twoFactorEnabled,
  userId,
  locked,
  setDeleteClicked,
  setResetPasswordClicked,
}) => {
  const mutationOptions = {
    variables: { userId },
  };

  const [disableTwoFactorAuthentication, disableTwoFactorAuthenticationState] =
    useDisableTwoFactorAuthenticationMutation(mutationOptions);

  const [suspendUser, suspendUserState] =
    useSuspendUserMutation(mutationOptions);

  const [unlockUser, unlockUserState] = useUnlockUserMutation(mutationOptions);

  const handleDisableTwoFactorAuthentication = () => {
    callMutationWithToastMessages(
      disableTwoFactorAuthentication,
      disableMessages,
    );
  };

  const handleSuspendUser = () => {
    const messages = suspended ? unsuspendMessages : suspendMessages;
    callMutationWithToastMessages(suspendUser, messages);
  };

  const handleUnlockUser = () => {
    callMutationWithToastMessages(unlockUser, unlockMessages);
  };

  const loading =
    disableTwoFactorAuthenticationState.loading ||
    suspendUserState.loading ||
    unlockUserState.loading;

  return (
    <ToggleableTooltip
      data-testid="userActions"
      tooltipBoxClassName={styles.tooltipBox}
      triggerType="button"
      triggerText="Actions"
      placement="bottomEnd"
      className={styles.actionButton}
    >
      <Link to={`/users/${userId}/add_credit`}>Add Credit</Link>
      <button
        type="button"
        className={`${styles.actionButton} textLink underline`}
        disabled={loading}
        onClick={() => handleSuspendUser()}
      >
        {suspended ? 'Unsuspend User' : 'Suspend User'}
      </button>
      {twoFactorEnabled && (
        <button
          type="button"
          className={`${styles.actionButton} textLink underline`}
          disabled={loading}
          onClick={() => handleDisableTwoFactorAuthentication()}
        >
          Disable Two-Factor Authentication
        </button>
      )}
      {locked && (
        <button
          type="button"
          className={`${styles.actionButton} textLink underline`}
          disabled={loading}
          onClick={() => handleUnlockUser()}
        >
          Unlock User
        </button>
      )}
      <button
        type="button"
        className={`${styles.actionButton} textLink underline`}
        disabled={loading}
        onClick={() => setResetPasswordClicked(true)}
      >
        Reset Password
      </button>
      <button
        type="button"
        className={`${styles.actionButton} textLink underline`}
        disabled={loading || !deletable}
        onClick={() => setDeleteClicked(true)}
      >
        Delete
      </button>
    </ToggleableTooltip>
  );
};

export default UserActions;
