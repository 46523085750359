import { ToggleableTooltip } from '@equitymultiple/react-eui';
import React from 'react';
import { Link } from 'react-router-dom';

import { Sponsor } from '../../../__generated__';

export interface Props {
  setSelectedSponsor: (sponsor: Sponsor | null) => void;
  sponsor: Sponsor | null;
}

const SponsorsActions: React.FC<Props> = ({ setSelectedSponsor, sponsor }) => {
  return (
    <div data-testid={`kebab-${sponsor?.id}`}>
      <ToggleableTooltip placement="leftStart">
        <Link to={`/sponsors/${sponsor?.id}`}>Edit</Link>
        <button
          onClick={() => setSelectedSponsor(sponsor)}
          type="button"
          className="textLink underline"
        >
          Delete
        </button>
      </ToggleableTooltip>
    </div>
  );
};

export default SponsorsActions;
