import { ToggleableTooltip } from '@equitymultiple/react-eui';
import React from 'react';
import { Link } from 'react-router-dom';

import { PostAuthor } from '../../../__generated__';

export interface Props {
  postAuthor: PostAuthor | null;
  setSelectedPostAuthor: (postAuthor: PostAuthor | null) => void;
}

const PostAuthorActions: React.FC<Props> = ({
  setSelectedPostAuthor,
  postAuthor,
}) => {
  return (
    <div data-testid={`kebab-${postAuthor?.id}`}>
      <ToggleableTooltip data-testid="toggleable-tooltip" placement="leftStart">
        <Link to={`/post_authors/${postAuthor?.id}/edit`}>Edit</Link>
        <button
          onClick={() => setSelectedPostAuthor(postAuthor)}
          type="button"
          className="textLink underline"
        >
          Delete
        </button>
      </ToggleableTooltip>
    </div>
  );
};

export default PostAuthorActions;
