import {
  AccreditationStatus,
  InvestmentTransaction,
  namedOperations,
  PaymentMethod,
  TransactionStatus,
  TransactionTypeCode,
  useCancelInvestmentTransactionMutation,
  useDeleteInvestmentTransactionMutation,
  useEditTransactionMutation,
  useGetInvestmentDetailsQuery,
  User,
  useRunInvestmentTransactionMutation,
} from '__generated__';
import {
  Button,
  Card,
  Select,
  Table,
  TableRow,
  ToggleableTooltip,
  Tooltip,
} from '@equitymultiple/react-eui';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import formatCurrency from 'utils/formatCurrency';
import {
  capitalize,
  enumValueToLabel,
  snakeToTitleCase,
} from 'utils/stringFormatting';
import { investmentAccountTypeLabels } from 'views/Accounts/constants';
import { formatClosingName } from 'views/Offerings/helpers';
import {
  eventTypes,
  paymentMethods,
  transactionStatuses,
  transactionTypes,
} from 'views/Payments/RunTransactions/constants';

import {
  investmentDetailstTableColumnHeaders,
  investmentTransactionsTableColumnHeaders,
} from '../constants';
import {
  formatAccountName,
  getAccreditationStatusColor,
  getPaymentMethodOptions,
} from '../helpers';
import { EditableTransactionValues } from '../types';
import * as styles from './InvestmentDetails.module.scss';

const runMessages = {
  loading: 'Running transaction(s)',
  error: 'An error occurred while running transaction(s)',
  success: 'Transaction(s) ran',
};

const cancelMessages = {
  loading: 'Cancelling transaction(s)',
  error: 'An error occurred while cancelling transaction(s)',
  success: 'Transaction(s) cancelled',
};

const deleteMessages = {
  loading: 'Deleting transaction(s)',
  error: 'An error occurred while deleting transaction(s)',
  success: 'Transaction(s) deleted',
};

const editMessages = {
  loading: 'Updating transaction',
  error: 'An error occurred while updating the transaction',
  success: 'Transaction updated',
};

const getModalContent = (
  transactions: InvestmentTransaction[],
  user: User,
  isDeleteModal?: boolean,
) => {
  const firstTransaction = transactions[0];
  const isAch = firstTransaction.paymentMethod === PaymentMethod.Ach;

  return (
    <div className="margin30">
      <strong>User: </strong> {`${user.firstName} ${user.lastName}`}
      <br />
      <strong>Payment Method:</strong>{' '}
      {paymentMethods[firstTransaction.paymentMethod]}
      <br />
      {isAch && !isDeleteModal && (
        <>
          <strong>Source Funding Account:</strong>{' '}
          {firstTransaction.sourceFundingSource.bankName}
          <br />
          <strong>Destination Funding Account:</strong>{' '}
          {firstTransaction.destinationFundingSource.bankName}
          <br />
        </>
      )}
      <strong>Transactions:</strong>
      <ol className={styles.transactionList}>
        {transactions.map((transaction) => (
          <li key={transaction.id}>
            {formatCurrency(transaction.amount)} (
            {transactionTypes[transaction.type]})
          </li>
        ))}
      </ol>
    </div>
  );
};

const getTransactionEditModalContent = (
  newTransactionValues: InvestmentTransaction,
  transactions: InvestmentTransaction[],
  updatedField: string,
) => {
  const existingTransaction = transactions.find(
    (transaction) => transaction.id === newTransactionValues.id,
  );

  switch (updatedField) {
    case 'paymentMethod':
      return (
        <p>
          Are you sure you want to update the payment method of this transaction
          (ID: {existingTransaction.id}) from{' '}
          <strong>{existingTransaction.paymentMethod.toUpperCase()}</strong> to{' '}
          <strong>{newTransactionValues.paymentMethod.toUpperCase()}</strong>?
        </p>
      );

    case 'type':
      return (
        <p>
          Are you sure you want to update the transaction type of this
          transaction (ID: {existingTransaction.id}) from{' '}
          <strong>{snakeToTitleCase(existingTransaction.type)}</strong> to{' '}
          <strong>{snakeToTitleCase(newTransactionValues.type)}</strong>?
        </p>
      );

    default:
      return '';
  }
};

const paymentMethodOptions = getPaymentMethodOptions();

const InvestmentDetails = () => {
  const [showRunModal, setShowRunModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editTransactionAttribute, setEditTransactionAttribute] =
    useState(null);
  const [selectedTransactionGroup, setSelectedTransactionGroup] =
    useState<InvestmentTransaction[]>(null);
  const [newTransactionValues, setNewTransactionValues] =
    useState<EditableTransactionValues>(null);

  const navigate = useNavigate();
  const { offeringId, id } = useParams();
  const { data, error, loading } = useGetInvestmentDetailsQuery({
    variables: {
      offeringId: offeringId,
      investmentId: id,
    },
  });

  const transactions = data?.investmentTransactions?.transactions;
  const investment = data?.investment.investment;
  const closing = data?.investment?.investment?.closing?.closing;
  const offering = data?.offering?.offering;
  const user = investment?.user;
  const investmentAccount = investment?.investmentAccount;
  const hasTransactions = transactions?.length > 0;

  const hasError = !!(
    error ||
    data?.investmentTransactions.error ||
    data?.investment.error ||
    data?.offering.error
  );

  const [runTransaction, runTransactionState] =
    useRunInvestmentTransactionMutation();
  const [cancelTransaction, cancelTransactionState] =
    useCancelInvestmentTransactionMutation();
  const [deleteTransaction, deleteTransactionState] =
    useDeleteInvestmentTransactionMutation();
  const [editTransaction, editTransactionState] = useEditTransactionMutation();

  const mutationOptions = {
    variables: {
      investmentId: id,
      transactionId: selectedTransactionGroup && selectedTransactionGroup[0].id,
    },
    refetchQueries: [namedOperations.Query.getInvestmentDetails],
  };

  const editTransactionMutationOptions = {
    variables: {
      transaction: { investmentId: id, ...newTransactionValues },
    },
  };

  const handleRunTransaction = () => {
    callMutationWithToastMessages(
      runTransaction,
      runMessages,
      mutationOptions,
    ).then(() => setShowRunModal(false));
  };

  const handleCancelTransaction = () => {
    callMutationWithToastMessages(
      cancelTransaction,
      cancelMessages,
      mutationOptions,
    ).then(() => setShowCancelModal(false));
  };

  const handleDeleteTransaction = () => {
    callMutationWithToastMessages(
      deleteTransaction,
      deleteMessages,
      mutationOptions,
    ).then(() => setShowDeleteModal(false));
  };

  const handleEditTransaction = () => {
    callMutationWithToastMessages(
      editTransaction,
      editMessages,
      editTransactionMutationOptions,
    ).then(() => setNewTransactionValues(null));
  };

  useEffect(() => {
    if (hasError) {
      toast.error('An error occurred while loading investment details');
      navigate(`/offerings/${offeringId}/investments`);
    }
  }, [hasError, offeringId, navigate]);

  const getInvestmentDetailsRows = () => [
    {
      cells: [
        investment.id,
        `${user.firstName} ${user.lastName}`,
        formatAccountName(investmentAccount),
        investmentAccountTypeLabels[investment.investmentAccount.type],
        formatClosingName(closing),
        capitalize(investment.status),
        <div
          key="accreditationStatus"
          className={getAccreditationStatusColor(
            investment.investmentAccount?.accreditationStatus,
          )}
        >
          {enumValueToLabel(
            AccreditationStatus,
            investment.investmentAccount?.accreditationStatus,
          )}
        </div>,
        formatCurrency(investment.amount),
      ],
    },
  ];

  const getCellsFromTransaction = (transaction: InvestmentTransaction) => [
    transaction.id,
    eventTypes[transaction.eventType],
    transactionTypes[transaction.type],
    <Select
      key="paymentMethod"
      id={`${transaction.id}paymentMethodSelect`}
      disabled={transaction.status !== TransactionStatus.Draft}
      value={transaction.paymentMethod}
      onChange={(value) => {
        if (value !== transaction.paymentMethod) {
          setEditTransactionAttribute('paymentMethod');
          setNewTransactionValues({
            id: transaction.id,
            paymentMethod: value as PaymentMethod,
          });
        }
      }}
      options={paymentMethodOptions}
    />,
    transaction.datePosted
      ? moment.utc(new Date(transaction.datePosted)).format('M/D/YYYY')
      : '',
    transaction.effectiveDate
      ? moment.utc(new Date(transaction.effectiveDate)).format('M/D/YYYY')
      : '',
    transaction.sourceFundingSource?.bankName,
    transaction.destinationFundingSource?.bankName,
    transactionStatuses[transaction.status],
    <div key={transaction.id}>
      {formatCurrency(transaction.amount as number)}
      {transaction.principalAmount && (
        <div className={styles.principalAmount}>
          <strong>Principal Amount:</strong>{' '}
          {formatCurrency(transaction.principalAmount as number)}
        </div>
      )}
    </div>,
  ];

  const getCollapsibleCell = (
    investmentTransactions: InvestmentTransaction[],
  ) => {
    return (
      <div className={styles.collapsedRowsWrap}>
        {investmentTransactions.map((transaction: InvestmentTransaction) => {
          const row = {
            cells: [...getCellsFromTransaction(transaction), ''],
          };
          return (
            <TableRow
              row={row}
              key={transaction.id}
              totalRow={false}
              hasCollapsibleSibling
            />
          );
        })}
      </div>
    );
  };

  const getTransactionRows = (transactionGroups: InvestmentTransaction[][]) =>
    transactionGroups.map((transactionGroup) => {
      const isAdditionalInterest =
        transactionGroup[0].type === TransactionTypeCode.AdditionalInterest;
      const showRunButton =
        !isAdditionalInterest &&
        (transactionGroup[0].status === 'draft' ||
          (transactionGroup[0].status === 'pending' &&
            [
              PaymentMethod.Check,
              PaymentMethod.Rollover,
              PaymentMethod.Wire,
              PaymentMethod.Redemption,
            ].includes(transactionGroup[0].paymentMethod)));
      const showCancelButton = ['draft', 'pending'].includes(
        transactionGroup[0].status,
      );
      const showDeleteButton =
        !isAdditionalInterest && transactionGroup[0].status === 'draft';
      const showCapitalCallButton =
        isAdditionalInterest && transactionGroup[0].status === 'draft';

      const showActions =
        showRunButton ||
        showCancelButton ||
        showDeleteButton ||
        showCapitalCallButton;

      return {
        cells: [
          ...getCellsFromTransaction(transactionGroup[0]),
          showActions && (
            <div
              className={styles.actions}
              data-testid={`kebab-${transactionGroup[0].id}`}
              key="actions"
            >
              <ToggleableTooltip placement="leftStart">
                {showRunButton && (
                  <button
                    type="button"
                    className="textLink underline"
                    onClick={() => {
                      setSelectedTransactionGroup(transactionGroup);
                      setShowRunModal(true);
                    }}
                  >
                    Run Transaction
                  </button>
                )}

                {showCapitalCallButton && (
                  <button
                    type="button"
                    className="textLink underline"
                    onClick={() => {
                      setEditTransactionAttribute('type');
                      setNewTransactionValues({
                        id: transactionGroup[0].id,
                        type: TransactionTypeCode.CapitalCall,
                      });
                    }}
                  >
                    Set to Capital Call Transaction
                  </button>
                )}

                {showCancelButton && (
                  <button
                    type="button"
                    className="textLink underline"
                    onClick={() => {
                      setSelectedTransactionGroup(transactionGroup);
                      setShowCancelModal(true);
                    }}
                  >
                    Cancel Transaction
                  </button>
                )}

                {showDeleteButton && (
                  <button
                    type="button"
                    className="textLink underline"
                    onClick={() => {
                      setSelectedTransactionGroup(transactionGroup);
                      setShowDeleteModal(true);
                    }}
                  >
                    Delete Transaction
                  </button>
                )}
              </ToggleableTooltip>
            </div>
          ),
        ],
        openCollapsibleCellByDefault: true,
        collapsibleCell:
          transactionGroup.length > 1 &&
          getCollapsibleCell(transactionGroup.slice(1)),
      };
    });

  const transactionsForRunModal = (
    transactionsGroup: InvestmentTransaction[],
  ) => {
    return transactionsGroup.filter(
      (tran) =>
        tran.status === TransactionStatus.Draft ||
        (tran.status === TransactionStatus.Pending &&
          tran.paymentMethod !== PaymentMethod.Ach),
    );
  };

  const getStepColor = (step) => {
    switch (step) {
      case 'review':
        return 'textGreen';
      default:
        return 'textOrange';
    }
  };

  return (
    <>
      <h2 data-testid="heading">
        {showRunModal && (
          <ConfirmationModal
            handleCloseModal={() => {
              setSelectedTransactionGroup(null);
              setShowRunModal(false);
            }}
            onSubmit={handleRunTransaction}
            title="Are you sure you want to run this transaction?"
            content={getModalContent(
              transactionsForRunModal(selectedTransactionGroup),
              user,
            )}
            buttonText="Yes, run it"
            loading={runTransactionState.loading}
          />
        )}
        {showCancelModal && (
          <ConfirmationModal
            handleCloseModal={() => {
              setSelectedTransactionGroup(null);
              setShowCancelModal(false);
            }}
            onSubmit={handleCancelTransaction}
            title="Are you sure you want to cancel this transaction?"
            content={getModalContent(selectedTransactionGroup, user)}
            buttonText="Yes, cancel it"
            loading={cancelTransactionState.loading}
          />
        )}
        {showDeleteModal && (
          <ConfirmationModal
            handleCloseModal={() => {
              setSelectedTransactionGroup(null);
              setShowDeleteModal(false);
            }}
            onSubmit={handleDeleteTransaction}
            title="Are you sure you want to delete this transaction?"
            content={getModalContent(selectedTransactionGroup, user, true)}
            buttonText="Yes, delete it"
            loading={deleteTransactionState.loading}
          />
        )}
        {!!newTransactionValues && (
          <ConfirmationModal
            handleCloseModal={() => {
              setNewTransactionValues(null);
            }}
            onSubmit={handleEditTransaction}
            title="Confirm Transaction Changes"
            content={getTransactionEditModalContent(
              newTransactionValues,
              transactions.flat(),
              editTransactionAttribute,
            )}
            buttonText="Yes, Update Transaction"
            loading={editTransactionState.loading}
          />
        )}
        {loading ? (
          <Skeleton width={700} style={{ maxWidth: '100%' }} />
        ) : (
          `${investmentAccount.entityName} (${
            investmentAccountTypeLabels[investmentAccount.type]
          })'s Investment in ${offering.title}`
        )}
      </h2>
      <h6 data-testid="currentStepHeading">
        {loading ? (
          <Skeleton width={700} style={{ maxWidth: '100%' }} />
        ) : (
          <div>
            Current Step:{' '}
            <span
              data-testid="currentStepText"
              className={`${getStepColor(investment.currentStep?.step)} ${styles.currentStepText}`}
            >
              {investment.currentStep?.step}
            </span>{' '}
            -{' '}
            <Link
              data-testid="currentStepLink"
              target="_blank"
              to={investment.currentStep?.route}
            >
              Marketplace Link
            </Link>
            <Tooltip
              data-testid="currentStepTooltip"
              tooltipContent="Copy this link to share with investors who want to complete or review their investment."
              className="inlineTooltip"
              infoIcon
            />
          </div>
        )}
      </h6>
      <Card>
        <Table
          data-testid="investmentDetailsTable"
          columnHeaders={investmentDetailstTableColumnHeaders}
          loading={loading}
          loadingRows={1}
          rows={!loading ? getInvestmentDetailsRows() : []}
        />
      </Card>
      <Card>
        <Row>
          <Col md={6}>
            <h4>Transactions</h4>
          </Col>
          <Col md={6}>
            <Button
              wrapper={
                <Link
                  to={`/offerings/${offeringId}/investments/${id}/transactions/new`}
                />
              }
              className="floatRight"
            >
              New Transaction
            </Button>
          </Col>
        </Row>
        <Table
          data-testid="transactionsTable"
          className={styles.table}
          columnHeaders={investmentTransactionsTableColumnHeaders}
          loading={loading}
          loadingRows={3}
          allowSorting={false}
          rows={
            hasTransactions && !loading
              ? getTransactionRows(transactions)
              : [{ cells: ['No transactions found'] }]
          }
        />
      </Card>
    </>
  );
};

export default InvestmentDetails;
