import { Button, Card, FileUploader, Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { setFieldProps } from '../../../../utils/formHelpers';
import { postAuthorSchema } from './validation';

// eslint-disable-next-line
type FormValues = Record<string, any>;

export interface Props {
  defaultValues?: DefaultValues<FormValues>;
  onSubmit(submitData: FormValues): unknown;
  submitting: boolean;
}

const PostAuthorForm: React.FC<Props> = ({
  onSubmit,
  submitting,
  defaultValues,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<DefaultValues<FormValues>>({
    mode: 'onBlur',
    resolver: yupResolver(postAuthorSchema),
    defaultValues: {
      firstName: defaultValues?.firstName || '',
      lastName: defaultValues?.lastName || '',
      title: defaultValues?.title || '',
      email: defaultValues?.email || '',
      avatar: defaultValues?.avatar || null,
    },
  });

  const formValues = getValues();

  useEffect(() => {
    if (defaultValues && formValues?.firstName === undefined) {
      reset({ ...defaultValues } as DefaultValues<FormValues>);
    }
  }, [defaultValues, reset, formValues?.firstName]);

  return (
    <Card className={`contentNarrow firstChildMarginTop0`}>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="postAuthorForm">
        <Row>
          <Col md={6}>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  {...setFieldProps(field, errors)}
                  label="First Name"
                />
              )}
            />
          </Col>
          <Col md={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  {...setFieldProps(field, errors)}
                  label="Last Name"
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  {...setFieldProps(field, errors)}
                  label="Title"
                />
              )}
            />
          </Col>
          <Col md={6}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  {...setFieldProps(field, errors)}
                  label="Email"
                />
              )}
            />
          </Col>
        </Row>
        <p>Avatar</p>
        <Controller
          name="avatar"
          control={control}
          render={({ field }) => (
            <FileUploader
              {...field}
              onRemove={() => field.onChange(null)}
              upload={(uploadData: File) => field.onChange(uploadData)}
              showImage
              existingFile={
                field.value?.url
                  ? {
                      ...field.value,
                      name:
                        field.value.url.split('/').pop() +
                        (field.value.url.includes('open-uri') ? '.jpg' : ''), // appending jpg for open uri uploads for preview
                    }
                  : field.value
              }
              errorMessage={errors.avatar?.message as string}
              acceptedFileTypes={['JPG', 'PNG']}
              helperText="Image should be in JPG or PNG only, maximum file size - 10 MB with a 1:1 aspect ratio"
            />
          )}
        />
        <Row>
          <Col className="alignItemsCenter" xs={8}>
            <Link
              type="button"
              className="textLink underline"
              to="/posts#post_authors"
            >
              Cancel
            </Link>
          </Col>
          <Col xs={4}>
            <Button type="submit" className="floatRight" loading={submitting}>
              Submit
            </Button>
          </Col>
        </Row>
      </form>
    </Card>
  );
};

export default PostAuthorForm;
