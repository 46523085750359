import {
  Closing,
  ClosingStage,
  InvestmentAccount,
  namedOperations,
  PaymentMethod,
  useCreateInvestmentMutation,
  useGetClosingsByOfferingIdQuery,
  useGetUserLazyQuery,
  useGetUsersLazyQuery,
} from '__generated__';
import {
  Button,
  Card,
  DateSelect,
  Input,
  Select,
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from '@mui/material';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import formatCurrency from 'utils/formatCurrency';
import { setDateSelectFieldProps, setFieldProps } from 'utils/formHelpers';
import { numberMaskOptions } from 'utils/masks';
import { capitalize } from 'utils/stringFormatting';
import { formatClosingName } from 'views/Offerings/helpers';

import { formatAccountName, getPaymentMethodOptions } from '../helpers';
import { investmentSchema } from './validation';

const messages = {
  loading: 'Creating investment',
  error: 'An error occurred while creating the investment',
  success: 'Investment created',
};

const getClosingOptions = (closings: Closing[]) => {
  const availableClosings = closings?.filter(
    (closing) => closing.stage !== ClosingStage.Draft,
  );

  return availableClosings.map((closing) => ({
    label: formatClosingName(closing),
    value: closing.id,
  }));
};

const getInvestmentAccountOptions = (
  investmentAccounts: InvestmentAccount[],
) => {
  if (investmentAccounts) {
    return investmentAccounts.map((investmentAccount) => ({
      label: formatAccountName(investmentAccount),
      value: investmentAccount.id,
    }));
  }

  return [];
};

const getBankAccountOptions = (
  investmentAccounts: InvestmentAccount[],
  selectedInvestmentAccountId: string,
) => {
  if (investmentAccounts) {
    const selectedInvestmentAccount = investmentAccounts.find(
      (investmentAccount) =>
        investmentAccount.id === selectedInvestmentAccountId,
    );

    return selectedInvestmentAccount.bankAccounts.data.map((bankAccount) => ({
      label: `${bankAccount.name} (${bankAccount.accountNumber})`,
      value: bankAccount.id,
    }));
  }

  return [];
};

const paymentMethodOptions = getPaymentMethodOptions();

const emFundPaymentMethodOptions = [
  {
    label: capitalize(PaymentMethod.Wire),
    value: PaymentMethod.Wire,
  },
];

const NewInvestment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [createInvestment, createInvestmentState] =
    useCreateInvestmentMutation();

  const submitting = createInvestmentState.loading;

  const {
    data: closingsData,
    loading: closingsLoading,
    error: closingsError,
  } = useGetClosingsByOfferingIdQuery({
    variables: {
      offeringId: id,
    },
  });
  const closings = closingsData?.closings?.closings;

  const getUsersVariables = {
    confirmed: true,
    pagination: {
      page: 1,
      pageSize: 50,
    },
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(investmentSchema),
    defaultValues: {
      user: null,
      closingId: null,
      investmentAccountId: null,
      amount: '',
      paymentMethod: null,
      bankAccountId: null,
      datePosted: null,
      effectiveDate: null,
    },
  });
  const user = watch('user');
  const paymentMethod = watch('paymentMethod');
  const investmentAccountId = watch('investmentAccountId');
  const investmentAmount = watch('amount');
  const minimumInvestmentAmount = closings?.[0].minimumInvestmentAmount;

  const [getUsers] = useGetUsersLazyQuery({
    notifyOnNetworkStatusChange: true,
  });
  const [modalOpen, setModalOpen] = useState(false);

  const [getUser, { data: userData, loading: userLoading, error: userError }] =
    useGetUserLazyQuery();

  const loading = closingsLoading;

  const isEmFundUser = user?.label?.includes('operations@equitymultiple.com');
  const showBankAccountField =
    investmentAccountId && paymentMethod === PaymentMethod.Ach;

  useEffect(() => {
    if (closingsError) {
      toast.error('An error occurred while loading the offering details');
      navigate('/');
    }
  }, [closingsError, navigate]);

  useEffect(() => {
    if (userError) {
      toast.error('An error occurred while loading the user');
    }
  }, [userError]);

  useEffect(() => {
    if (user?.value) {
      getUser({
        variables: { userId: user.value },
      });
    }
  }, [getUser, user]);

  const handleCreateInvestment = (customAmount?: boolean) => {
    const values = getValues();

    // eslint-disable-next-line
    const submitValues: Record<string, any> = {
      ...values,
      offeringId: id,
      userId: values.user.value,
      amount: Number(values.amount),
    };

    delete submitValues.user;

    if (customAmount)
      submitValues.customMinimumInvestmentAmount = submitValues.amount;

    callMutationWithToastMessages(createInvestment, messages, {
      variables: {
        investment: submitValues,
      },
      refetchQueries: [namedOperations.Query.getInvestments],
    }).then((res) => {
      setModalOpen(false);
      if (res.data.createInvestment.investment?.id) {
        navigate(`/offerings/${id}/investments`);
      }
    });
  };

  const onSubmit = (values) => {
    if (!isEmFundUser) {
      delete values.datePosted;
      delete values.effectiveDate;
    }

    if (!showBankAccountField) {
      delete values.bankAccountId;
    }

    if (values.amount < minimumInvestmentAmount) {
      setModalOpen(true);
    } else {
      handleCreateInvestment();
    }
  };

  const loadUserOptions = (searchValue, callback) => {
    getUsers({
      variables: {
        ...getUsersVariables,
        filter: searchValue,
      },
      notifyOnNetworkStatusChange: true,
    }).then((res) => {
      const userOptions = res.data.users.data.map((userRes) => ({
        label: `${userRes.firstName} ${userRes.lastName} - (${userRes.email}, ID: ${userRes.id})`,
        value: userRes.id,
      }));

      callback(userOptions);
    });
  };

  const userOptions = debounce(loadUserOptions, 300);

  return (
    <>
      {modalOpen && (
        <ConfirmationModal
          handleCloseModal={() => setModalOpen(false)}
          onSubmit={() => handleCreateInvestment(true)}
          title="Investment amount less than minimum"
          content={
            <p>
              The investment amount entered ({formatCurrency(investmentAmount)})
              is less than the minimum investment amount of{' '}
              {formatCurrency(minimumInvestmentAmount)}. Are you sure you want
              to proceed?
            </p>
          }
          buttonText="Yes, Create Investment"
          loading={submitting}
        />
      )}

      <h2 className="contentNarrow">
        {closingsLoading ? (
          <Skeleton width={500} style={{ maxWidth: '100%' }} />
        ) : (
          `Create Investment in ${closingsData?.offering.offering.title}`
        )}
      </h2>
      <Card className="contentNarrow">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={8}>
              <Controller
                name="user"
                control={control}
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    label="User"
                    async
                    defaultOptions
                    loadOptions={userOptions}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    placeholder="Search by email, ID, first name or last name"
                    data-testid="userSelect"
                  />
                )}
              />
            </Col>
          </Row>
          <hr className="marginTop10 margin30" />
          {user && (
            <div data-testid="investmentFields">
              <Row>
                <Col md={6}>
                  <Controller
                    name="closingId"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...setFieldProps(field, errors)}
                        label="Closing"
                        name="closing"
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        options={getClosingOptions(closings)}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Controller
                    name="investmentAccountId"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...setFieldProps(field, errors)}
                        label="Investment Account"
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        disabled={userLoading}
                        options={getInvestmentAccountOptions(
                          userData?.user?.user?.investmentAccounts.data,
                        )}
                        data-testid="accountSelect"
                      />
                    )}
                  />
                </Col>
                <Col md={6}>
                  <Controller
                    name="amount"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...setFieldProps(field, errors)}
                        label="Amount"
                        dollarMask
                        allowDecimal
                        inputMaskOptions={numberMaskOptions}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Controller
                    name="paymentMethod"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...setFieldProps(field, errors)}
                        label="Payment Method (Optional)"
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        options={
                          isEmFundUser
                            ? emFundPaymentMethodOptions
                            : paymentMethodOptions
                        }
                        data-testid="paymentMethodSelect"
                      />
                    )}
                  />
                </Col>
                {showBankAccountField && (
                  <Col md={6}>
                    <Controller
                      name="bankAccountId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...setFieldProps(field, errors)}
                          label="Bank Account"
                          onChange={(value) => {
                            field.onChange(value);
                          }}
                          disabled={userLoading}
                          options={getBankAccountOptions(
                            userData?.user?.user?.investmentAccounts.data,
                            investmentAccountId,
                          )}
                          data-testid="bankAccountSelect"
                        />
                      )}
                    />
                  </Col>
                )}
              </Row>
              {isEmFundUser && (
                <Row>
                  <Col md={6} data-testid="effectiveDate">
                    <Controller
                      name="effectiveDate"
                      control={control}
                      render={({ field }) => (
                        <DateSelect
                          {...setDateSelectFieldProps(field, errors)}
                          label="Effective Date"
                        />
                      )}
                    />
                  </Col>
                  <Col md={6} data-testid="datePosted">
                    <Controller
                      name="datePosted"
                      control={control}
                      render={({ field }) => (
                        <DateSelect
                          {...setDateSelectFieldProps(field, errors)}
                          label="Date Posted"
                        />
                      )}
                    />
                  </Col>
                </Row>
              )}
            </div>
          )}

          <Row className="marginTop30">
            <Col className="alignItemsCenter" xs={4}>
              <Link
                type="button"
                className="textLink underline"
                to={`/offerings/${id}/investments`}
              >
                Cancel
              </Link>
            </Col>
            <Col xs={8}>
              <Button
                type="submit"
                disabled={loading}
                loading={submitting}
                className="floatRight"
              >
                Create Investment
              </Button>
            </Col>
          </Row>
        </form>
      </Card>
    </>
  );
};

export default NewInvestment;
