import { Button, EMLoadingIcon, Input } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, DefaultValues, useForm } from 'react-hook-form';

import {
  ApiTokenInput,
  useCreateApiTokenMutation,
  useGetApiTokensQuery,
  useToggleApiTokenMutation,
} from '../../../__generated__';
import * as loadingIconStyles from '../../../styles/components/EMLoadingIcon.module.scss';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import { setFieldProps } from '../../../utils/formHelpers';
import { createApiTokenSchema } from './validation';

const createMessages = {
  loading: 'Creating an api token',
  error: 'An error occurred while attempting to create the api token',
  success: 'Created',
};

const toggleMessages = {
  loading: 'Updating the api token',
  error: 'An error occurred while attempting to update the api token',
  success: 'Updated',
};

type FormValues = ApiTokenInput;

const ApiTokens: React.FC = () => {
  const { data, loading, refetch } = useGetApiTokensQuery();

  const hasApiTokens = data?.apiTokens.data;

  const defaultValues = {
    name: '',
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DefaultValues<FormValues>>({
    mode: 'onBlur',
    resolver: yupResolver(createApiTokenSchema),
    defaultValues,
  });

  const [createApiToken, createApiTokenState] = useCreateApiTokenMutation();
  const creating = createApiTokenState.loading;

  const onSubmit = async (submitData: Partial<FormValues>) => {
    await callMutationWithToastMessages(createApiToken, createMessages, {
      variables: {
        apiToken: submitData as ApiTokenInput,
      },
    });
    refetch();
    reset({ name: '' });
  };

  const [toggleApiToken, toggleApiTokenState] = useToggleApiTokenMutation();
  const toggling = toggleApiTokenState.loading;

  const toggleToken = (tokenId: string | null | undefined) => {
    if (tokenId) {
      callMutationWithToastMessages(toggleApiToken, toggleMessages, {
        variables: {
          apiTokenId: tokenId,
        },
      });
    }
  };

  return (
    <>
      <h2 data-testid="apiTokensHeading" className="marginTop0">
        API Tokens
      </h2>

      <form onSubmit={handleSubmit(onSubmit)} data-testid="transactionForm">
        <Row>
          <Col xl={4} lg={6} md={6}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  {...setFieldProps(field, errors)}
                  label="API Application Name"
                />
              )}
            />
          </Col>
        </Row>

        <Button type="submit" className="margin40" loading={creating}>
          Create
        </Button>
      </form>

      <div id="apiTokensWrapper">
        {loading ? (
          <EMLoadingIcon className={loadingIconStyles.cardLoader} />
        ) : (
          hasApiTokens &&
          data.apiTokens.data.map((token) => {
            return (
              <div key={token.id}>
                <h4>
                  {token.name} ({token.active ? 'Active' : 'Inactive'})
                </h4>

                <Row>
                  <Col xl={3} lg={3} md={3}>
                    <h6>API Key</h6>
                    <div className="codeBlock">{token.token}</div>
                  </Col>
                  <Col xl={6} lg={6} md={6}>
                    <h6>API Secret</h6>
                    <div className="codeBlock">{token.secret}</div>
                  </Col>
                  <Col xl={3} lg={3} md={3}>
                    <h6>{token.access} Hits</h6>
                    <button
                      className="textLink underline marginTop10"
                      type="button"
                      onClick={() => toggleToken(token.id)}
                      disabled={toggling}
                    >
                      {token.active ? 'Deactivate' : 'Activate'}
                    </button>
                  </Col>
                </Row>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default ApiTokens;
