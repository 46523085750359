import { ToggleableTooltip } from '@equitymultiple/react-eui';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import DeactivateInvestmentAccountModal from './DeactivateInvestmentAccountModal';

export interface Props {
  accountId: string;
  status: string | null | undefined;
}

const InvestmentAccountActions: React.FC<Props> = ({ accountId, status }) => {
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);

  const handleToggleDeactivateModal = () => {
    setDeactivateModalOpen(!deactivateModalOpen);
  };

  return (
    <div data-testid="investmentAccountActions">
      {deactivateModalOpen && (
        <DeactivateInvestmentAccountModal
          handleCloseModal={handleToggleDeactivateModal}
          id={accountId}
          status={status}
        />
      )}
      <ToggleableTooltip data-testid="toggleable-tooltip" placement="leftStart">
        <Link to={`/investment_accounts/${accountId}/edit`}>Edit</Link>
        <button
          type="button"
          className="textLink underline"
          onClick={handleToggleDeactivateModal}
        >
          Deactivate
        </button>
      </ToggleableTooltip>
    </div>
  );
};

export default InvestmentAccountActions;
