import {
  AccreditationStatus,
  Closing,
  ClosingStage,
  Investment,
  InvestmentStatus,
  OfferingCampaign,
  useGetClosingsByOfferingIdQuery,
  useGetInvestmentsQuery,
} from '__generated__';
import {
  Alert,
  Button,
  Card,
  Input,
  Select,
  Table,
  ToggleableTooltip,
} from '@equitymultiple/react-eui';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import formatCurrency from 'utils/formatCurrency';
import { enumValueToLabel } from 'utils/stringFormatting';
import { investmentAccountTypeLabels } from 'views/Accounts/constants';

import { formatClosingName } from '../helpers';
import {
  investmentStatusOptions,
  investmentTableColumnHeaders,
} from './constants';
import { formatAccountName, getAccreditationStatusColor } from './helpers';
import InvestmentEditModal from './InvestmentEditModal/InvestmentEditModal';
import InvestmentFilters from './InvestmentFilters/InvestmentFilters';
import * as styles from './OfferingInvestments.module.scss';
import { EditableInvestmentValues } from './types';

const getinvestmentStatusOptions = (
  investmentStatus: InvestmentStatus,
  isEmFundAccount: boolean,
) => {
  const signedAndInterestEmFundAccountOptions = [
    investmentStatusOptions.interest,
    investmentStatusOptions.signed,
    investmentStatusOptions.funded,
    investmentStatusOptions.cancelled,
    investmentStatusOptions.dropped,
  ];

  switch (investmentStatus) {
    case InvestmentStatus.Waitlist:
      return [
        investmentStatusOptions.waitlist,
        investmentStatusOptions.interest,
        investmentStatusOptions.cancelled,
        investmentStatusOptions.dropped,
      ];
    case InvestmentStatus.Interest:
      if (isEmFundAccount) {
        return signedAndInterestEmFundAccountOptions;
      } else {
        return [
          investmentStatusOptions.interest,
          investmentStatusOptions.cancelled,
          investmentStatusOptions.dropped,
        ];
      }
    case InvestmentStatus.Signed:
      if (isEmFundAccount) {
        return signedAndInterestEmFundAccountOptions;
      } else {
        return [
          investmentStatusOptions.signed,
          investmentStatusOptions.cancelled,
          investmentStatusOptions.dropped,
        ];
      }
    case InvestmentStatus.Funded:
      return [investmentStatusOptions.funded];
    case InvestmentStatus.Cancelled:
      return [
        investmentStatusOptions.cancelled,
        investmentStatusOptions.interest,
      ];
    case InvestmentStatus.Dropped:
      return [
        investmentStatusOptions.dropped,
        investmentStatusOptions.interest,
      ];
  }
};

const getClosingOptions = (closings: Closing[], closingId: string) => {
  const currentClosing = closings?.find((closing) => closing.id === closingId);

  if ([ClosingStage.Exited].includes(currentClosing?.stage)) {
    return [
      {
        label: formatClosingName(currentClosing),
        value: currentClosing?.id,
      },
    ];
  } else {
    const availableClosings = closings?.filter((closing) =>
      [
        ClosingStage.Interest,
        ClosingStage.Active,
        ClosingStage.Waitlist,
        ClosingStage.Funded,
        ClosingStage.Cashflowing,
        ClosingStage.Exited,
      ].includes(closing.stage),
    );

    return availableClosings?.map((closing) => ({
      label: formatClosingName(closing),
      value: closing.id,
    }));
  }
};

const OfferingInvestments = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [investmentStatusFilters, setInvestmentStatusFilters] = useState<
    InvestmentStatus[]
  >([]);
  const [accreditationStatusFilters, setAccreditationStatusFilters] = useState<
    AccreditationStatus[]
  >([]);
  const [closingFilters, setClosingFilters] = useState<number[]>([]);
  const [fetching, setFetching] = useState(false);
  const [newInvestmentValues, setNewInvestmentValues] =
    useState<EditableInvestmentValues>(null);

  const variables = {
    offeringId: id,
  };

  const {
    data: investmentsData,
    error: investmentsError,
    loading: investmentsLoading,
    refetch,
  } = useGetInvestmentsQuery({
    variables,
  });
  const {
    data: closingsData,
    loading: closingsLoading,
    error: closingsError,
  } = useGetClosingsByOfferingIdQuery({
    variables,
  });

  const loading = investmentsLoading || closingsLoading || fetching;
  const hasError = !!(
    investmentsError ||
    investmentsData?.investments?.error ||
    closingsError ||
    closingsData?.closings?.error
  );
  const investments = investmentsData?.investments?.investments;
  const closings = closingsData?.closings?.closings;
  const campaign = closingsData?.offering?.offering?.campaign;

  const filteredInvestments = investments?.filter((investment) => {
    const searchValueLowerCase = searchValue.toLowerCase();
    const { investmentAccount, user } = investment;
    return (
      investment.id.toString().includes(searchValueLowerCase) ||
      user.firstName.toLowerCase().includes(searchValueLowerCase) ||
      user.lastName.toLowerCase().includes(searchValueLowerCase) ||
      investmentAccount?.entityName
        ?.toLowerCase()
        .includes(searchValueLowerCase)
    );
  });

  useEffect(() => {
    if (hasError) {
      toast.error('An error occurred while loading investments');
      navigate('/');
    }
  }, [hasError, navigate]);

  const handleRefetchForFilterChange = (
    refetchQuery: Record<string, (string | number)[]>,
  ) => {
    setFetching(true);
    refetch({
      ...refetchQuery,
    }).finally(() => {
      setFetching(false);
    });
  };

  const handleInvestmentStatusFilterChange = (newFilters: string[]) => {
    if (!fetching) {
      handleRefetchForFilterChange({ investmentStatus: newFilters });
      setInvestmentStatusFilters([...newFilters] as InvestmentStatus[]);
    }
  };

  const handleAccreditationStatusFilterChange = (newFilters: string[]) => {
    if (!fetching) {
      handleRefetchForFilterChange({ accreditationStatus: newFilters });
      setAccreditationStatusFilters([...newFilters] as AccreditationStatus[]);
    }
  };

  const handleClosingFilterChange = (newFilters: number[]) => {
    if (!fetching) {
      handleRefetchForFilterChange({ closingIds: newFilters });
      setClosingFilters([...newFilters]);
    }
  };

  const getRows = () =>
    filteredInvestments.map((investment) => {
      const { user, investmentAccount } = investment;
      const formattedAccountType = investmentAccount.type
        ? investmentAccountTypeLabels[investmentAccount.type]
        : ' ';

      const closingOptions = getClosingOptions(closings, investment.closingId);

      const closingName = closingOptions?.find(
        (closing) => closing.value === investment.closingId,
      )?.label;

      const accountOptions = user.investmentAccounts.data.map((account) => ({
        label: formatAccountName(account),
        value: account.id,
      }));

      const isEmFundsAccount = user.email === 'operations@equitymultiple.com';

      const showReinvestmentElections =
        !investment.redeemed && campaign == OfferingCampaign.AlpineNote;

      return {
        cells: investment
          ? [
              investment.id,
              {
                value: `${user.firstName} ${user.lastName}`,
                sortableValue: user.lastName.toLowerCase(),
              },
              {
                value: (
                  <Select
                    key="account"
                    id={`${investment.id}accountSelect`}
                    label="Account"
                    disabled={loading}
                    value={investment.investmentAccount.id}
                    onChange={(value) => {
                      if (value !== investment.investmentAccount.id) {
                        setNewInvestmentValues({
                          id: investment.id,
                          investmentAccountId: value as string,
                        });
                      }
                    }}
                    options={accountOptions}
                    className={styles.accountSelect}
                  />
                ),
                sortableValue: investmentAccount.entityName
                  ? investmentAccount.entityName.toLowerCase()
                  : ' ',
              },
              {
                value: formattedAccountType,
                sortableValue: formattedAccountType,
              },
              {
                value: (
                  <Select
                    key="closing"
                    id={`${investment.id}closingSelect`}
                    label="Closing"
                    disabled={loading}
                    value={investment.closingId}
                    onChange={(value) => {
                      if (value !== investment.closingId) {
                        setNewInvestmentValues({
                          id: investment.id,
                          closingId: value.toString(),
                          closingName,
                        });
                      }
                    }}
                    options={closingOptions}
                    className={styles.closingSelect}
                  />
                ),
                sortableValue: closingName,
              },
              {
                value: (
                  <Select
                    key="status"
                    id={`${investment.id}statusSelect`}
                    label="Status"
                    disabled={loading || investment.status === 'funded'}
                    value={investment.status}
                    onChange={(value) => {
                      if (value !== investment.status) {
                        setNewInvestmentValues({
                          id: investment.id,
                          status: value as InvestmentStatus,
                        });
                      }
                    }}
                    options={getinvestmentStatusOptions(
                      investment.status,
                      isEmFundsAccount,
                    )}
                    className={styles.statusSelect}
                    tooltip={
                      investment.status === 'funded' &&
                      'No status update available. Please post a refund transaction to drop the investment.'
                    }
                  />
                ),
                sortableValue: investment.status,
              },
              {
                value: (
                  <div
                    key="accreditationStatus"
                    data-testid="accreditationStatus"
                    className={getAccreditationStatusColor(
                      investment.investmentAccount?.accreditationStatus,
                    )}
                  >
                    {enumValueToLabel(
                      AccreditationStatus,
                      investment.investmentAccount?.accreditationStatus,
                    )}
                  </div>
                ),
                sortableValue: investment.investmentAccount?.accreditationStatus
                  ? investment.investmentAccount.accreditationStatus
                  : ' ',
              },
              {
                value: formatCurrency(investment.amount),
                sortableValue: investment.amount,
              },
              <div data-testid={`kebab-${investment.id}`} key="actions">
                <ToggleableTooltip
                  placement="leftStart"
                  tooltipBoxClassName={styles.tooltipBox}
                >
                  <Link
                    className="textLink underline"
                    to={`/offerings/${id}/investments/${investment.id}/details`}
                  >
                    View Details
                  </Link>
                  <Link
                    className="textLink underline"
                    to={`/offerings/${id}/investments/${investment.id}/refund`}
                  >
                    Refund Investment
                  </Link>
                  {showReinvestmentElections && (
                    <Link
                      className="textLink underline"
                      to={`/offerings/${id}/elections/${investment.id}`}
                    >
                      Edit Reinvestment Elections
                    </Link>
                  )}
                </ToggleableTooltip>
              </div>,
            ]
          : [],
      };
    });

  const getSelectedInvestment = () => {
    if (newInvestmentValues)
      return investments.find(
        (investment) => investment.id === newInvestmentValues.id,
      );
    return null;
  };

  const investmentsCount = filteredInvestments?.length;
  const hasInvestments = investmentsCount > 0;
  const investmentsHeading = loading ? (
    <Skeleton width={250} />
  ) : (
    `Showing ${investmentsCount} Investment${investmentsCount !== 1 ? 's' : ''}`
  );
  const noClosings = !closingsLoading && closings?.length < 1;

  return (
    <>
      <InvestmentEditModal
        isOpen={!!newInvestmentValues}
        handleClose={() => setNewInvestmentValues(null)}
        investment={getSelectedInvestment() as Investment}
        newInvestmentValues={newInvestmentValues}
        closings={closings}
        offeringId={id}
      />

      <h2>
        {closingsLoading ? (
          <Skeleton width={500} style={{ maxWidth: '100%' }} />
        ) : (
          `Investments in ${closingsData?.offering.offering.title}`
        )}
      </h2>
      <Row>
        <Col xl={2} md={3} className={styles.filtersContainer}>
          {closings && (
            <InvestmentFilters
              accreditationStatusFilters={accreditationStatusFilters}
              closingFilters={closingFilters}
              closings={closings}
              investmentStatusFilters={investmentStatusFilters}
              handleAccreditationStatusFilterChange={
                handleAccreditationStatusFilterChange
              }
              handleClosingFilterChange={handleClosingFilterChange}
              handleInvestmentStatusFilterChange={
                handleInvestmentStatusFilterChange
              }
            />
          )}
        </Col>
        <Col xl={10} md={9} className={styles.tableContainer}>
          <Card className="firstChildMarginTop0">
            <h4>{investmentsHeading}</h4>
            <Row className="margin30">
              {noClosings ? (
                <Alert type="negative">
                  There are no closings attached to this offering.
                </Alert>
              ) : (
                <>
                  <Col lg={8} md={6} push={{ lg: 4, md: 6 }}>
                    <Button
                      wrapper={<Link to={`/offerings/${id}/investments/new`} />}
                      className={styles.button}
                    >
                      New Investment
                    </Button>
                  </Col>
                  <Col lg={4} md={6} pull={{ lg: 8, md: 6 }}>
                    <Input
                      id="investmentsSearch"
                      label="Search Investments"
                      placeholder="ID, Investor Name or Entity Name"
                      onChange={(event) => setSearchValue(event.target.value)}
                      value={searchValue}
                      disabled={loading}
                    />
                  </Col>
                </>
              )}
            </Row>
            <Table
              columnHeaders={investmentTableColumnHeaders}
              loading={loading}
              loadingRows={10}
              allowSorting={[0, 1, 2, 3, 4, 5, 6, 7]}
              rows={
                hasInvestments && !loading
                  ? getRows()
                  : [{ cells: ['No investments found'] }]
              }
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OfferingInvestments;
