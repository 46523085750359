import { ToggleableTooltip } from '@equitymultiple/react-eui';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  namedOperations,
  OfferingStatus,
  useArchiveOfferingMutation,
  useDownloadOfferingDocsMutation,
  usePublishOfferingMutation,
  useUnpublishOfferingMutation,
} from '../../../__generated__';
import { deleteObjectFromCache } from '../../../utils/apolloCacheHelpers';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import * as styles from './OfferingActions.module.scss';

export interface Props {
  id: string;
  status: OfferingStatus;
}

const publishMessages = {
  loading: 'Publishing',
  error: 'Failed to publish offering',
  success: 'Offering published',
};

const unpublishMessages = {
  loading: 'Unpublishing',
  error: 'Failed to unpublish offering',
  success: 'Offering unpublished',
};

const archiveMessages = {
  loading: 'Archiving',
  error: 'Failed to archive offering',
  success: 'Offering archived',
};

const downloadDocsMessages = {
  loading: 'Generating',
  error: 'Failed to get documents',
  success:
    'Document queued for download. Please check your mail in a few minutes',
};

const OfferingActions: React.FC<Props> = ({ id, status }) => {
  const mutationOptions = {
    variables: { offeringId: id },
  };

  const [publish, publishState] = usePublishOfferingMutation({
    ...mutationOptions,
    refetchQueries: [namedOperations.Query.getOfferings],
  });

  const [unpublish, unpublishState] = useUnpublishOfferingMutation({
    ...mutationOptions,
    refetchQueries: [namedOperations.Query.getOfferings],
  });

  const [archive, archiveState] = useArchiveOfferingMutation({
    ...mutationOptions,
    update(cache) {
      deleteObjectFromCache(cache, 'OfferingList', id);
    },
  });

  const [downloadOfferingDocs, downloadOfferingDocsState] =
    useDownloadOfferingDocsMutation();

  const loading =
    publishState.loading ||
    unpublishState.loading ||
    archiveState.loading ||
    downloadOfferingDocsState.loading;

  const isPublished = status === 'published';

  const handlePublish = () => {
    if (isPublished) {
      callMutationWithToastMessages(unpublish, unpublishMessages);
    } else {
      callMutationWithToastMessages(publish, publishMessages);
    }
  };

  return (
    <div data-testid="offeringActions">
      <ToggleableTooltip
        placement="leftStart"
        tooltipBoxClassName={styles.tooltipBox}
      >
        <button
          type="button"
          className="textLink underline textLeft"
          disabled={loading}
          onClick={() => handlePublish()}
        >
          {isPublished ? 'Unpublish' : 'Publish'}
        </button>
        <Link to={`/offerings/${id}/edit`}>Edit</Link>
        <button
          type="button"
          className="textLink underline textLeft"
          disabled={loading}
          onClick={() =>
            callMutationWithToastMessages(archive, archiveMessages)
          }
        >
          Archive
        </button>
        <button
          type="button"
          className="textLink underline textLeft"
          disabled={loading}
          onClick={() =>
            callMutationWithToastMessages(
              downloadOfferingDocs,
              downloadDocsMessages,
              {
                variables: {
                  offeringId: id,
                  type: 'subscription_document',
                },
              },
            )
          }
        >
          Download SubDocs
        </button>
        <button
          type="button"
          className="textLink underline textLeft"
          disabled={loading}
          onClick={() =>
            callMutationWithToastMessages(
              downloadOfferingDocs,
              downloadDocsMessages,
              {
                variables: {
                  offeringId: id,
                  type: 'w9_document',
                },
              },
            )
          }
        >
          Download All W-9 Documents
        </button>
        <button
          type="button"
          className="textLink underline textLeft"
          disabled={loading}
          onClick={() =>
            callMutationWithToastMessages(
              downloadOfferingDocs,
              downloadDocsMessages,
              {
                variables: {
                  offeringId: id,
                  type: 'tax_document',
                },
              },
            )
          }
        >
          Download Tax Documents
        </button>
        <Link to={`/offerings/${id}/bank_account`}>Bank Account</Link>
        <Link to={`/offerings/${id}/documents`}>Document Uploader</Link>
      </ToggleableTooltip>
    </div>
  );
};

export default OfferingActions;
