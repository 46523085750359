import { Button, Select } from '@equitymultiple/react-eui';
import Option from '@equitymultiple/react-eui/dist/types/Select';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';

import {
  useEditSettingMutation,
  useGetActiveOfferingsQuery,
  useGetOfferingLazyQuery,
  useGetSettingsQuery,
} from '../../../__generated__';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';

const messages = {
  loading: 'Saving highlighted offering',
  error: 'An error occurred while attempting to save the highlighted offering',
  success: 'Highlighted offering saved',
};

const InvestPage: React.FC = () => {
  const [offeringOptions, setOfferingOptions] = useState<Option[]>();
  const [selectedOffering, setSelectedOffering] = useState('');

  const { data, loading } = useGetSettingsQuery({
    variables: {
      name: 'highlighted_offering',
    },
  });

  const { data: activeOfferingsData, loading: loadingActiveOfferings } =
    useGetActiveOfferingsQuery();

  const [getOffering] = useGetOfferingLazyQuery();

  const [editSetting, editSettingState] = useEditSettingMutation();
  const { loading: saving } = editSettingState;

  const isLoading = loading || loadingActiveOfferings;

  useEffect(() => {
    if (data && activeOfferingsData) {
      const activeOfferings = activeOfferingsData.activeOfferings.data;

      const selectedOfferingId = data.settings?.settings[0]?.value;

      const buildOfferingOptions = async () => {
        const options = [
          {
            label: 'None (Remove Highlighted Offering)',
            value: 'none',
          },
        ];

        if (selectedOfferingId) {
          setSelectedOffering(selectedOfferingId);

          // The currently highlighted offering is no longer active and doesn't exist in the active offerings response,
          // so we need to call getOffering to get its title and add it as an option
          if (
            !activeOfferings.some(
              (offering) => offering.id === selectedOfferingId,
            )
          ) {
            const res = await getOffering({
              variables: {
                offeringId: selectedOfferingId,
              },
            });

            const offeringTitle = res.data?.offering?.offering?.title;
            if (offeringTitle) {
              options.push({
                label: `${offeringTitle} (Inactive)`,
                value: selectedOfferingId,
              });
            }
          }
        }

        activeOfferings.forEach((offering) => {
          options.push({
            label: offering.title,
            value: offering.id,
          });
        });

        return options;
      };

      buildOfferingOptions().then(setOfferingOptions);
    }
  }, [data, activeOfferingsData, getOffering]);

  const saveHighlightedOffering = () => {
    callMutationWithToastMessages(editSetting, messages, {
      variables: {
        setting: {
          id: data.settings.settings[0].id,
          value: selectedOffering === 'none' ? '' : selectedOffering,
        },
      },
    });
  };

  return (
    <>
      <h2 data-testid="investPageHeading" className="marginTop0">
        Invest Page
      </h2>
      <h4 data-testid="highlightedOfferingHeading">Highlighted Offering</h4>

      <Row>
        <Col xl={6} lg={8}>
          <Select
            label="Highlighted Offering"
            id="highlightedOffering"
            data-testid="highlightedOffering"
            options={offeringOptions}
            disabled={isLoading || saving}
            onChange={(val) => {
              setSelectedOffering(val);
            }}
            value={selectedOffering}
          />
        </Col>
      </Row>
      <Button
        onClick={saveHighlightedOffering}
        disabled={isLoading}
        loading={saving}
      >
        Save Highlighted Offering
      </Button>
    </>
  );
};

export default InvestPage;
