import { ToggleableTooltip } from '@equitymultiple/react-eui';
import React, { useState } from 'react';
import { canCancel } from 'utils/transactionHelpers';

import {
  InvestmentStatus,
  namedOperations,
  PaymentMethod,
  PaymentTransaction,
  TransactionEventTypeCode,
  TransactionStatus,
  TransactionTypeCode,
  useCancelTransactionMutation,
  useGetDwollaTransferHrefLazyQuery,
  useRetryTransactionMutation,
  useRunTransactionMutation,
} from '../../../__generated__';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import formatCurrency from '../../../utils/formatCurrency';
import { paymentMethods, transactionTypes } from './constants';
import * as styles from './TransactionActions.module.scss';

interface Props {
  offeringId: string;
  transactions: PaymentTransaction[];
}

const runMessages = {
  loading: 'Running transactions',
  error: 'An error occurred while running the transactions',
  success: 'Transactions ran',
};

const retryMessages = {
  loading: 'Retrying transactions',
  error: 'An error occurred while retrying the transactions',
  success: 'Transactions retried',
};

const cancelMessages = {
  loading: 'Cancelling transactions',
  error: 'An error occurred while cancelling the transactions',
  success: 'Transactions cancelled',
};

const dwollaLinkMessages = {
  loading: 'Getting Dwolla link',
  error: 'An error occurred while getting the Dwolla link',
  success: 'Dwolla link retrieved, opening in a new tab',
};

const getTransactionDetails = (
  transactions: PaymentTransaction[],
  status = 'draft',
) => {
  const transaction = transactions[0];
  const filterTransaction = (singleTransaction: PaymentTransaction) => {
    if (status === 'draft') {
      return (
        singleTransaction.status === 'draft' ||
        (singleTransaction.paymentMethod !== PaymentMethod.Ach &&
          singleTransaction.status === 'pending')
      );
    } else if (status === 'failed') {
      return (
        singleTransaction.status === 'failed' && !singleTransaction.retried
      );
    } else {
      return singleTransaction.status === status;
    }
  };

  return (
    <div className="margin30">
      <strong>User: </strong>
      {transaction.user?.firstName} {transaction.user?.lastName}
      <br />
      <strong>Transactions: </strong>
      <ol className={styles.transactionList}>
        {transactions
          .filter((singleTransaction) => filterTransaction(singleTransaction))
          .map((singleTransaction) => {
            return (
              <li key={singleTransaction.id}>
                {singleTransaction.amount &&
                  formatCurrency(singleTransaction.amount)}{' '}
                (
                {singleTransaction.type &&
                  transactionTypes[singleTransaction.type]}
                {singleTransaction.type && ', '}
                {singleTransaction.paymentMethod &&
                  paymentMethods[singleTransaction.paymentMethod]}
                )
              </li>
            );
          })}
      </ol>
    </div>
  );
};

const hideToggleableTooltip = (transaction: PaymentTransaction) => {
  if (
    transaction.paymentMethod !== PaymentMethod.Ach &&
    transaction.status === TransactionStatus.Posted
  ) {
    return true;
  }

  if (transaction.eventType === TransactionEventTypeCode.Contribution) {
    const investmentStatus = transaction.investment?.status as InvestmentStatus;
    const transactionType = transaction.type as TransactionTypeCode;

    const investmentStatusRunnable = [
      InvestmentStatus.Funded,
      InvestmentStatus.Signed,
    ].includes(investmentStatus);

    const transactionTypeRunnable = [
      TransactionTypeCode.Investment,
      TransactionTypeCode.CapitalCall,
    ].includes(transactionType);

    return !investmentStatusRunnable || !transactionTypeRunnable;
  }

  return false;
};

const TransactionActions: React.FC<Props> = ({ offeringId, transactions }) => {
  const [showRunModal, setShowRunModal] = useState(false);
  const [showRetryModal, setShowRetryModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const transaction = transactions[0];
  const transactionId = transaction.id as string;
  const draftTransactions = transactions.filter(
    (tran) =>
      tran.status === TransactionStatus.Draft ||
      (tran.status === TransactionStatus.Pending &&
        tran.paymentMethod !== PaymentMethod.Ach),
  );

  const [runTransaction, runTransactionState] = useRunTransactionMutation({
    variables: {
      offeringId: offeringId as string,
      transactionId,
    },
    refetchQueries: [namedOperations.Query.getPaymentTransactions],
  });

  const [retryTransaction, retryTransactionState] = useRetryTransactionMutation(
    {
      variables: {
        offeringId: offeringId as string,
        transactionId,
      },
      refetchQueries: [namedOperations.Query.getPaymentTransactions],
    },
  );

  const [cancelTransaction, cancelTransactionState] =
    useCancelTransactionMutation({
      variables: {
        offeringId: offeringId as string,
        transactionId,
      },
      refetchQueries: [namedOperations.Query.getPaymentTransactions],
    });

  const [getDwollaLink, getDwollaLinkState] = useGetDwollaTransferHrefLazyQuery(
    {
      variables: {
        transactionId,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const handleCancel = () => {
    callMutationWithToastMessages(cancelTransaction, cancelMessages).then(
      () => {
        setShowCancelModal(false);
      },
    );
  };

  const handleRun = () => {
    callMutationWithToastMessages(runTransaction, runMessages).then(() => {
      setShowRunModal(false);
    });
  };

  const handleRetry = async () => {
    await callMutationWithToastMessages(retryTransaction, retryMessages).then(
      () => {
        setShowRetryModal(false);
      },
    );
  };

  const redirectToDwolla = () => {
    callMutationWithToastMessages(getDwollaLink, dwollaLinkMessages).then(
      (res) => {
        const dwollaLink = res.data?.dwollaTransferHref.href;
        if (dwollaLink) window.open(dwollaLink);
      },
    );
  };

  const status = transaction.status as string;
  const modalTitleEnd =
    transactions.length > 1 ? 'these transactions' : 'this transaction';
  const modalButtonEnd = transactions.length > 1 ? 'them' : 'it';
  const showViewInDwollaLink =
    transaction.paymentMethod === PaymentMethod.Ach &&
    ['pending', 'posted', 'failed'].includes(status);

  const showRetryButton =
    status === TransactionStatus.Failed &&
    (transaction.eventType === TransactionEventTypeCode.Contribution ||
      transaction.eventType === TransactionEventTypeCode.Distribution);

  const paymentMethod =
    transaction.paymentMethod && paymentMethods[transaction.paymentMethod];

  return hideToggleableTooltip(transaction) ? null : (
    <div data-testid={'transactionActions' + transaction.id}>
      {showRunModal && (
        <ConfirmationModal
          handleCloseModal={() => setShowRunModal(false)}
          onSubmit={handleRun}
          title={`Are you sure you want to run ${modalTitleEnd}?`}
          content={getTransactionDetails(draftTransactions)}
          buttonText={`Yes, run ${modalButtonEnd}`}
          loading={runTransactionState.loading}
        />
      )}
      {showRetryModal && (
        <ConfirmationModal
          handleCloseModal={() => setShowRetryModal(false)}
          onSubmit={handleRetry}
          title={`Are you sure you want to retry ${modalTitleEnd}?`}
          content={getTransactionDetails(transactions, 'failed')}
          buttonText={`Yes, retry ${modalButtonEnd}`}
          loading={retryTransactionState.loading}
        />
      )}
      {showCancelModal && (
        <ConfirmationModal
          handleCloseModal={() => setShowCancelModal(false)}
          onSubmit={handleCancel}
          title={`Are you sure you want to cancel ${modalTitleEnd}?`}
          content={getTransactionDetails(transactions, 'pending')}
          buttonText={`Yes, cancel ${modalButtonEnd}`}
          loading={cancelTransactionState.loading}
        />
      )}
      <ToggleableTooltip
        placement="leftStart"
        tooltipBoxClassName={styles.tooltip}
      >
        {(status === TransactionStatus.Draft ||
          (status === TransactionStatus.Pending &&
            paymentMethod !== PaymentMethod.Ach)) && (
          <button
            type="button"
            className="textLink textLeft underline"
            onClick={() => setShowRunModal(true)}
          >
            Run
          </button>
        )}

        {showRetryButton && (
          <button
            type="button"
            className="textLink textLeft underline"
            disabled={transaction.retried}
            onClick={() => setShowRetryModal(true)}
          >
            {transaction.retried ? 'Retried' : 'Retry'}
          </button>
        )}

        {canCancel(transaction) && (
          <button
            type="button"
            className="textLink textLeft underline"
            onClick={() => setShowCancelModal(true)}
          >
            Cancel
          </button>
        )}

        {showViewInDwollaLink && (
          <button
            type="button"
            className="textLink textLeft underline"
            onClick={redirectToDwolla}
            disabled={getDwollaLinkState.loading}
          >
            View in Dwolla
          </button>
        )}
      </ToggleableTooltip>
    </div>
  );
};

export default TransactionActions;
